<template>
  <footer class="footer">
    <span>Designed and built by <router-link to="/">Turab Saygun</router-link></span>
  </footer>
</template>

<script>
export default {
  name: 'FooterMain'
}
</script>

<style scoped>
.footer {
  background-color: #f2f2f2;
  border-top: 1px solid #e5e5e5;
  font-family: Inter, Arial, Helvetica, sans-serif;
  padding-top: 32px;
  padding-bottom: 96px;
  text-align: center;
}

.footer span {
  color: #5b6c7a;
  font-size: 1.4rem;
}

.footer a {
  color: #5b6c7a;
  border-bottom: none;
}

.footer a:hover {
  text-decoration: underline;
}
</style>
