<template>
  <div class="contact">
    <h1>Contact</h1>
    <p>To get in touch with me feel free to send me a message.</p>
    <p>Email: <a :href="pre + dec">{{ dec }}</a></p>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  setup () {
    const pre = atob('bWFpbHRvOg==')
    const dec = atob('dHVyYWJzYXlndW5AZ21haWwuY29t')

    return {
      pre,
      dec
    }
  }
}
</script>
