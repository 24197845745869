<template>
  <HeaderMain></HeaderMain>
  <div class="page">
    <div class="container">
      <router-view/>
    </div>
  </div>
  <FooterMain></FooterMain>
</template>

<script>
import HeaderMain from './components/HeaderMain.vue'
import FooterMain from './components/FooterMain.vue'

export default {
  name: 'App',
  components: {
    HeaderMain,
    FooterMain
  }
}
</script>
