<template>
  <div class="photography">
    <h1>Photography</h1>
    <p>Gallery with pictures I've taken. Click on an image to enlarge it.</p>
    <ul class="photo-gallery ul-reset">
      <li class="photo-gallery-item" v-for="(image, index) in images" :key="index">
        <img :src="'/photos/thumbs/' + image.filename" v-on:click="toggleModal(index, image.filename)">
      </li>
    </ul>
    <div class="photo-modal" v-on:click="showModal = false" :class="{ show: showModal }">
      <div class="photo-modal-nav">
        <div class="photo-modal-nav-control prev" v-on:click.stop="prevImage()">
          <i class="arrow left"></i>
        </div>
        <div class="photo-modal-nav-index">
          <span>{{ index + 1 }} / {{ images.length }}</span>
        </div>
        <div class="photo-modal-nav-control next" v-on:click.stop="nextImage()">
          <i class="arrow right"></i>
        </div>
      </div>
      <img class="photo-modal-image" :src="'/photos/' + images[index].filename" v-if="showModal">
      <img src="@/assets/loader.gif" v-else>
    </div>
  </div>
</template>

<script>
import imagesData from '@/data/images-data.json'
import { ref } from 'vue'

export default {
  name: 'Photography',
  setup () {
    const images = imagesData
    let showModal = ref(false)
    let index = ref(Number)
    let filename = ref(String)

    function toggleModal (imgIndex, imgFilename) {
      index.value = imgIndex
      filename.value = imgFilename
      showModal.value = !showModal.value
    }

    function nextImage () {
      let ind = index.value + 1
      if (ind >= images.length) {
        ind = 0
      }

      index.value = ind
    }

    function prevImage () {
      let ind = index.value - 1
      if (ind < 0) {
        ind = images.length - 1
      }

      index.value = ind
    }

    return {
      images,
      showModal,
      index,
      filename,
      toggleModal,
      nextImage,
      prevImage
    }
  }
}
</script>

<style scoped>
.photo-gallery {
  display: flex;
  flex-wrap: wrap;
}

.photo-gallery-item {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-grow: 1;
}

.photo-gallery-item img {
  border-radius: 4px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  cursor: pointer;
}

.photo-gallery-item img:active {
  opacity: 0.8;
}

.photo-modal {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 200ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.photo-modal.show {
  opacity: 1;
  visibility: visible;
}

.photo-modal img {
  max-width: 100%;
  max-height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.photo-modal-nav {
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #000000;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
}

.photo-modal-nav-control {
  width: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.photo-modal-nav-control:hover i {
  border-color: #ffffff;
}

.photo-modal-nav-control:active i {
  border-color: #acb7bf;
}

.photo-modal-nav-control.prev {
  padding: 16px 24px 16px 28px;
}

.photo-modal-nav-control.next {
  padding: 16px 28px 16px 24px;
}

.photo-modal-nav-index {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.photo-modal-nav-index span {
  color: #ffffff;
  font-family: Metropolis, Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
}

.arrow {
  border: solid #acb7bf;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 6px;
}

.arrow.left {
  transform: rotate(135deg);
}

.arrow.right {
  transform: rotate(-45deg);
}
</style>
