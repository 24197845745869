<template>
  <div class="home">
    <h1>Personal website and portfolio of Turab Saygun. Projects, design and photography.</h1>
    <p>Hello, I'm Turab Saygun, designer and developer with a university degree in Engineering Physics with specialization in scientific computing.</p>
    <p>The field of webtechnology has always been an area of interest for me, so keeping up with the latest in the field and expanding my skills is something I enjoy doing. I have built several websites where I've worked on both the design and layout but also the server side technology and database modelling. A list of selected projects I've worked on can be found at the <router-link to="/projects">Projects</router-link> page, along with some information about technologies used in each project.</p>
    <p>I've also got a passion for the art of photography, I always appreciate and take the opportunity to explore new places and take photos. A gallery with pictures I've taken are showcased at the <router-link to="/photography">Photography</router-link> page. The viewer used in the gallery was custom built specifically for this page.</p>
    <p>If you would like to contact me feel free to send me a message through the <router-link to="/contact">Contact</router-link> page.</p>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
