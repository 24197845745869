<template>
  <header class="header">
    <div class="header-menu" v-on:click="showMenu = true">
      <svg class="header-menu-icon" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
        <path fill="#5b6c7a" d="m392 511.963h-272c-66.168 0-120-53.832-120-120v-168.271c0-37.191 17.655-72.86 47.226-95.415l136-103.729c42.849-32.681 102.699-32.681 145.548 0l43.226 32.94v-25.525c1.056-26.536 38.953-26.516 40 0v65.911c0 7.603-4.311 14.548-11.125 17.923-6.814 3.374-14.951 2.592-20.998-2.015l-75.355-57.423c-28.572-21.792-68.474-21.792-97.039-.005l-136 103.729c-19.713 15.035-31.483 38.814-31.483 63.609v168.271c0 44.112 35.888 80 80 80h272c44.112 0 80-35.888 80-80v-168.271c0-25.099-11.637-48.959-31.129-63.827-8.783-6.699-10.472-19.249-3.773-28.031 6.699-8.783 19.25-10.472 28.031-3.773 29.349 22.384 46.871 58.134 46.871 95.631v168.271c0 66.168-53.832 120-120 120zm-176-281c-13.807 0-25 11.193-25 25 1.321 33.17 48.691 33.145 50 0 0-13.808-11.193-25-25-25zm105 24.999c-1.321 33.17-48.691 33.145-50 0 1.321-33.17 48.691-33.144 50 0zm-80 80c-1.321 33.17-48.691 33.145-50 0 1.321-33.17 48.691-33.144 50 0zm80 0c-1.321 33.17-48.691 33.145-50 0 1.321-33.17 48.691-33.144 50 0z"/>
      </svg>
    </div>
    <div class="header-logo">
      <img class="header-logo-image" src="@/assets/turab-saygun-logo.svg" title="Turab Saygun" alt="Turab Saygun logo">
    </div>
    <router-link to="/contact">
      <div class="header-contact">
        <svg class="header-menu-icon" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
          <path fill="#5b6c7a" d="m392 458h-272c-66.168 0-120-53.832-120-120v-164c0-66.168 53.832-120 120-120h272c66.168 0 120 53.832 120 120v164c0 66.168-53.832 120-120 120zm-272-364c-44.112 0-80 35.888-80 80v164c0 44.112 35.888 80 80 80h272c44.112 0 80-35.888 80-80v-164c0-44.112-35.888-80-80-80zm209.286 197.406 98.855-75.512c8.778-6.705 10.458-19.256 3.753-28.034-6.706-8.778-19.256-10.458-28.034-3.753l-98.843 75.503c-28.541 21.769-68.4 21.785-96.96.055l-95.781-74.455c-8.721-6.78-21.287-5.205-28.065 3.516-6.779 8.721-5.205 21.286 3.516 28.065l95.855 74.512c.048.038.097.075.146.112 21.424 16.34 47.094 24.509 72.77 24.509s51.357-8.174 72.788-24.518z"/>
        </svg>
      </div>
    </router-link>
  </header>
  <nav class="nav" :class="{ show: showMenu }">
    <div class="nav-header" v-on:click="showMenu = false">
      <svg class="header-menu-icon" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
        <path fill="#5b6c7a" d="m392 511.963h-272c-66.168 0-120-53.832-120-120v-168.271c0-37.191 17.655-72.86 47.226-95.415l136-103.729c42.849-32.681 102.699-32.681 145.548 0l43.226 32.94v-25.525c1.056-26.536 38.953-26.516 40 0v65.911c0 7.603-4.311 14.548-11.125 17.923-6.814 3.374-14.951 2.592-20.998-2.015l-75.355-57.423c-28.572-21.792-68.474-21.792-97.039-.005l-136 103.729c-19.713 15.035-31.483 38.814-31.483 63.609v168.271c0 44.112 35.888 80 80 80h272c44.112 0 80-35.888 80-80v-168.271c0-25.099-11.637-48.959-31.129-63.827-8.783-6.699-10.472-19.249-3.773-28.031 6.699-8.783 19.25-10.472 28.031-3.773 29.349 22.384 46.871 58.134 46.871 95.631v168.271c0 66.168-53.832 120-120 120zm-176-281c-13.807 0-25 11.193-25 25 1.321 33.17 48.691 33.145 50 0 0-13.808-11.193-25-25-25zm105 24.999c-1.321 33.17-48.691 33.145-50 0 1.321-33.17 48.691-33.144 50 0zm-80 80c-1.321 33.17-48.691 33.145-50 0 1.321-33.17 48.691-33.144 50 0zm80 0c-1.321 33.17-48.691 33.145-50 0 1.321-33.17 48.691-33.144 50 0z"/>
      </svg>
    </div>
    <div class="nav-content">
      <ul class="nav-content-list ul-reset" v-on:click="showMenu = false">
        <li class="nav-content-list-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="nav-content-list-item">
          <router-link to="/projects">Projects</router-link>
        </li>
        <li class="nav-content-list-item">
          <router-link to="/photography">Photography</router-link>
        </li>
        <li class="nav-content-list-item">
          <router-link to="/contact">Contact</router-link>
        </li>
      </ul>
    </div>
    <div class="nav-overlay" v-if="showMenu" v-on:click.self="showMenu = false"></div>
  </nav>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'HeaderMain',
  setup () {
    let showMenu = ref(false)

    return {
      showMenu
    }
  }
}
</script>

<style scoped>
.header {
  background-color: #ffffff;
  border-bottom: 1px solid #ededed;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 1;
}

.header a {
  border-bottom: none;
}

.header-menu,
.header-contact {
  padding: 16px;
  cursor: pointer;
  display: flex;
}

.header-menu:active,
.header-contact:active {
  opacity: 0.6;
}

.header-menu-icon {
  width: 26px;
  height: 26px;
}

.header-logo {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.header-logo-image {
  height: 36px;
}

.nav-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: -1;
}

.nav {
  width: 260px;
  position: fixed;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease-in-out;
}

.nav.show {
  opacity: 1;
  visibility: visible;
}

.nav-header {
  background-color: #1f2326;
  border-bottom: 1px solid #0a0c0d;
  padding: 16px;
  cursor: pointer;
  display: flex;
}

.nav-content {
  background-color: #313a42;
  border-bottom-right-radius: 12px;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.15);
}

.nav-content-list {
  padding-top: 12px;
  padding-bottom: 16px;
}

.nav-content-list-item a {
  border-bottom: none;
  color: #ffffff;
  padding: 12px 22px 12px 22px;
  display: flex;
}

.nav-content-list-item a:hover {
  font-weight: 700;
  text-decoration: none;
}
</style>
