<template>
  <div class="page-not-found">
    <h1>Page not found</h1>
    <p>The page you were looking for could not be found.</p>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

export default {
  name: 'PageNotFound',
  setup () {
    onMounted (() => {
      let meta = document.createElement('meta')
      meta.setAttribute('name', 'robots')
      document.head.appendChild(meta)
      meta.setAttribute('content', 'noindex')
    })

    onBeforeRouteLeave (() => {
      document.querySelector('[name="robots"]').remove()
    })
  }
}
</script>
