<template>
  <div class="projects">
    <h1>Projects</h1>
    <p>Here you'll find a list of selected projects I've been working on along with some information about my role and technologies used.</p>
    <ul class="projects ul-reset">
      <li class="project-item">
        <h2 class="project-title">Gluo</h2>
        <span class="project-link">
          <a href="https://www.gluo.se">https://www.gluo.se</a>
        </span>
        <p><span class="project-label">Role:</span> Founder, programming and design</p>
        <p>An easy to use marketplace for buying and selling everything from furniture, clothing, collectibles to electronics and cars. The application was built as a responsive SPA (Single-page application) with a dedicated and custom built REST API serving the data. This design pattern makes the service highly lightweight and performant for the end user.</p>
        <span class="project-technologies"><span class="project-label">Front-end:</span> HTML5, SCSS and JavaScript (<a href="https://vuejs.org">Vue.js</a> framework)</span>
        <span class="project-technologies"><span class="project-label">Back-end:</span> PHP (<a href="https://lumen.laravel.com">Lumen</a> micro framework), MySQL</span>
      </li>
      <li class="project-item">
        <h2 class="project-title">Libris24</h2>
        <span class="project-link">
          <a href="https://www.libris24.com">https://www.libris24.com</a>
        </span>
        <p><span class="project-label">Role:</span> Founder, programming and design</p>
        <p>Service that lets primarily students find and sell their textbooks. Special care was taken into making the site responsive with a simple intuitive user interface and a powerful search engine with filtering options. To create a book listing the user has to signup and login to the application and this functionality was implemented through a highly secure, custom built authorization and authentication system.</p>
        <span class="project-technologies"><span class="project-label">Front-end:</span> HTML5, SCSS and JavaScript</span>
        <span class="project-technologies"><span class="project-label">Back-end:</span> PHP (<a href="https://phalconphp.com">Phalcon</a> framework), MySQL</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Projects'
}
</script>

<style scoped>
.project-item {
  border-top: 1px solid #ededed;
  padding-top: 32px;
  padding-bottom: 32px;
}

.project-item:last-child {
  padding-bottom: 0;
}

.project-title {
  margin-bottom: 16px;
}

.project-label {
  color: #5b6c7a;
}

.project-link {
  margin-bottom: 8px;
  display: block;
}

.project-technologies {
  margin-bottom: 16px;
  display: block;
}
</style>
