import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import Home from '../views/Home.vue'
import Projects from '../views/Projects.vue'
import Photos from '../views/Photography.vue'
import Contact from '../views/Contact.vue'
import PageNotFound from '../views/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      title: 'Turab Saygun – Projects'
    }
  },
  {
    path: '/photography',
    name: 'Photography',
    component: Photos,
    meta: {
      title: 'Turab Saygun – Photography'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'Turab Saygun – Contact'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.afterEach((to) => {
  document.title = to.meta.title || 'Turab Saygun – Personal website and portfolio'

  axios.post(process.env.VUE_APP_API_ENDPOINT + '/visitors/track', {
      referrerUrl: document.referrer,
      targetUrl: window.location.href
    })
    .then(() => {})
    .catch(() => {})
})

export default router
